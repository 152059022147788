// doctor price detail

.priceDetails-container {
  display: flex;
  height: 80px;
  align-items: center;


  .mentorProfileDetails {
    position: relative;
    display: flex;
    align-items: center;
  }

  & .cost-hoverContainer {
    display: none;
    position: absolute;
    left: 100%;
    top: -25%;
    white-space: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding-left: 6px;
    padding-right: 10px;
    background: #F8FAFB;
    height: 3rem;
    margin-left: 6px;
    border-radius: 5px;

    p {
      opacity: 0.8;
      font-size: 16px;
    }
  }

  & .info-icon {
    position: relative;
    cursor: pointer;
    width: 16px;
    margin-left: auto;
    opacity: 0.8;

    &:hover + .cost-hoverContainer {
      display: flex;
    }
  }
}
